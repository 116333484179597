import React, {useEffect, useState} from 'react';
import TitleBlock from "../TitleBlock"
import Dialog from '@material-ui/core/Dialog';
import Avatar from '@material-ui/core/Avatar';
import IconButton from '@material-ui/core/IconButton';
import Close from '@material-ui/icons/Close';
import ContactForm from "./ContactForm";
import SuccessPage from "./SuccessPage";
import queryString from "query-string";
import {navigate} from "gatsby";
import manager from "../../images/team_sasha.jpg";
import "./style.styl";

const widgetName = "apply_form"

export const onOpenApplyForm = (id, location) => {
    const queryParams = queryString.parse((location || {}).search);
    queryParams[widgetName] = id || "true"
    navigate(location.pathname + "?" + queryString.stringify(queryParams))
}

export default function ApplyForm(props) {
    const {location, isFullscreen} = props;
    const [submitted, setSubmitted] = useState(false)
    const queryParams = queryString.parse((location || {}).search);
    const serviceId = queryParams[widgetName] || ""
    const open = !!queryParams[widgetName]


    const onClose = () => {
        delete queryParams[widgetName]
        navigate(location.pathname + (Object.keys(queryParams).length ? ("?" + queryString.stringify(queryParams)) : ""))
    }


    useEffect(() => {
        if (submitted) {
            setTimeout(() => onClose(), 10000)
        }
    }, [submitted])

    if (submitted) {
        return <SuccessPage show={submitted} onMainPage={() => {setSubmitted(false); onClose()}}/>
    }


    return (
        <Dialog
            fullScreen={isFullscreen}
            open={open}
            maxWidth="lg"
            onClose={onClose}
            >

                <div className="hire-dialog u-relative">
                    <IconButton size="large"
                                onClick={onClose}
                                className="u-absolute u-right10 u-top10"
                                variant="raised">
                        <Close className="u-fontSize36"/>
                    </IconButton>
                    <div className="left u-padding25">
                        <TitleBlock {...props}
                                    title={<span>Request a Proposal</span>}
                                    description="Let's work together"/>
                                <ContactForm {...props} serviceId={serviceId} onSubmit={() => {setSubmitted(true);}}/>
                    </div>
                    <div className="right u-flexCenter u-padding25">
                        <div className="u-sizeFullWidth u-padding25 u-flexColumn u-alignItemsCenter u-justifyContentCenter">
                            <div className="u-opacity50 u-textAlignCenter u-maxWidth200">
                                Your Request is being processed by
                            </div>
                            <div>
                                <Avatar className="u-width100 u-height100 u-marginVertical10" alt="Pavel" src={manager} />
                            </div>

                                <TitleBlock
                                            title="Pavel"
                                            description="Project Manager"/>
                        </div>
                    </div>
                </div>


        </Dialog>
    );
}
