import React, {useState, useEffect} from 'react';
import { Link } from "gatsby"
import "./style.styl";

const words = ["WEB", "LEADS", "ADS"];

export function LogoWithAnimation(props) {
    const [index, setIndex] = useState(0);
    const [subIndex, setSubIndex] = useState(0);
    const [blink, setBlink] = useState(true);
    const [reverse, setReverse] = useState(false);

    useEffect(() => {
        if (index === words.length) {
            setSubIndex(0);
            setIndex(0)
            return
        }

        if ( subIndex === words[index].length + 1 && !reverse ) {
          setReverse(true);
          return;
        }

        if (subIndex === 0 && reverse) {
          setReverse(false);
          setIndex((prev) => (prev + 1) === words.length ? 0 : (prev + 1));
          return;
        }


        const timeout = setTimeout(() => {
          setSubIndex((prev) => prev + (reverse ? -1 : 1));
      }, Math.max(reverse ? 75 : subIndex === words[index].length ? 3000 :
                    150, parseInt(Math.random() * 350)));

        return () => clearTimeout(timeout);
      }, [subIndex, index, reverse]);



    useEffect(() => {
      const timeout2 = setTimeout(() => {
        setBlink((prev) => !prev);
      }, 500);
      return () => clearTimeout(timeout2);
    }, [blink]);


    return (
        <Link to="/">
            <span className="u-flexCenter u-cursorPointer u-fontWeightBold u-fontSize22 u-textColorNormal"
                style={{fontFamily: "RobotoBold"}}>
                <span style={{color: "#222e64"}}>WE</span><span style={{color: "#222e64"}}>SCALE</span><span style={{color: "#82c0f6"}}>{`${words[index].substring(0, subIndex)}${blink ? "|" : " "}`}</span>
            </span>
        </Link>
    )
}


export default function Logo(props) {

    return (
        <Link to="/">
            <span className="u-flexCenter u-cursorPointer u-fontWeightBold u-fontSize22 u-textColorNormal"
                style={{fontFamily: "RobotoBold"}}>
                <span style={{color: "#222e64"}}>WE</span><span style={{color: "#222e64"}}>SCALE</span>
            </span>
        </Link>
    )
}
