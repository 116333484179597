import React from 'react';
import Logo from "../../components/Logo";
import {onOpenApplyForm} from "../ApplyForm";
import { Link } from "gatsby";
import {services} from "../Header";
import './style.styl';


export default function Footer(props) {


    const containerLogo = (
        <span className="u-flexColumn">
            <div className="logo-box">
                 <Logo/>
            </div>
            <div className="about-box u-inlineBlock">
                  <p>Web and Marketing Agency</p>
            </div>

            <div className="u-marginTop20">
                <span className="button-dark-blue" onClick={() => onOpenApplyForm("true", props.location)}>
                    Lets Talk
                </span>

                <Link to="/we-hire">
                    <span className="button-blue u-marginLeft10">
                        We Hire
                    </span>
                </Link>
            </div>
        </span>
    )


    const linkBox = (
        <div className="link-box u-sizeFullWidth u-xs-marginTop20 u-flex u-justifyContentSpaceBetween u-xs-flexColumn">
            {
                services.map((service, i) => {
                    return (
                        <div key={i} className="u-marginLeft20 u-xs-marginBottom20 u-xs-marginLeft0">
                            <Link key={i} to={service.link}>
                                <div className="service-link u-marginBottom10 u-fontWeightBold u-fontSize16">{service.name}</div>
                            </Link>
                            {
                                (service.children || []).map((subservice, j) => {
                                    return (
                                        <Link key={j} to={subservice.link}>
                                            <div className="link u-marginBottom10">{subservice.name}</div>
                                        </Link>
                                    )
                                })
                            }
                        </div>
                    )
                })
            }
        </div>
    )



    return (
          <>
              <footer className="footer u-sizeFullWidth">
                  <div className="container">
                      <div className="u-flex u-justifyContentSpaceBetween u-xs-flexColumnReverse">
                          <div className="u-xs-marginTop20">
                              {containerLogo}
                          </div>

                          <div className="u-sizeFullWidth u-maxWidth600">
                              {linkBox}
                          </div>
                      </div>
                  </div>
              </footer>

              <div className="copyright">
                  <div className="container u-flexCenter u-justifyContentCenter u-textAlignCenter">
                      Copyright © 2024 Wescale, All Rights Reserved.
                  </div>
              </div>
          </>
    );

}
