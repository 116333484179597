import React from "react";
import favicon from "../../images/favicon.ico";
import { Helmet } from "react-helmet";

const siteMetadata = {
    defaultTitle: "Wescale - Web & Marketing Agency",
    defaultDescription: "Experienced team of Digital Marketers and Web Developers",
    siteUrl: "https://wescaleweb.com",
    defaultImage: "/images/main.jpg"
}



const SEO = ({ location, title, description, image, article }) => {

  const url = location ? location.pathname : ""
  //const { site } = useStaticQuery(query)
  const {
    defaultTitle,
    defaultDescription,
    siteUrl,
    defaultImage,
  } = siteMetadata

  const seo = {
    title: title || defaultTitle,
    description: description || defaultDescription,
    image: `${siteUrl}${image || defaultImage}`,
    url: `${siteUrl}${url}`,
  }

  return (
      <Helmet title={seo.title}>
          <meta name="description" content={seo.description} />
          <meta name="image" content={seo.image} />
          <link rel="icon" href={favicon}/>

          {seo.url && <meta property="og:url" content={seo.url} />}

          {(article ? true : null) && <meta property="og:type" content="article" />}

          {seo.title && <meta property="og:title" content={seo.title} />}

          {seo.description && (
            <meta property="og:description" content={seo.description} />
          )}

          {seo.image && <meta property="og:image" content={seo.image} />}
      </Helmet>
  )
}


export default SEO


SEO.defaultProps = {
  title: null,
  description: null,
  image: null,
  article: false,
}
