import React from 'react';
import FormControl from '@material-ui/core/FormControl';
import FormHelperText from '@material-ui/core/FormHelperText';
import Select from '@material-ui/core/Select';
import InputLabel from '@material-ui/core/InputLabel';
import ListSubheader from '@material-ui/core/ListSubheader';
import MenuItem from '@material-ui/core/MenuItem';
import "./style.styl";


export default function MuiSelect(props) {
    const {items, menu, buttonComponent, iconComponent, selected, variant, native, classNameSelect, helperText, error, color, margin, label, htmlFor, onSelect, onName, className, disabled} = props;

  

    return (
        <FormControl variant={variant || "outlined"} color={color} margin={margin || "dense"} className={className || ""} error={!!error} disabled={disabled}>
            <InputLabel id={(htmlFor || "") + "_label_id"}>
              {label}
            </InputLabel>
            <Select
              value={selected}
              native={native}
              id={(htmlFor || "")}
              labelId={(htmlFor || "") + "_label_id"}
              label={(variant || "outlined") === "outlined" ? (label || undefined) : undefined}
              onChange={(event, item) => onSelect(event.target.value, item && item.props.item)}
              inputProps={{id: htmlFor, ...(classNameSelect ? {className: classNameSelect} : {})}}
            >
            {
                items.map((item, i) => {
                    if (native) {
                        if (item.header) {
                            return (
                                null
                            )
                        }
                        return (
                            <option key={i} value={item.value}>{onName ? onName(item) : item.name}</option>
                        )
                    }
                    if (item.header) {
                        return <ListSubheader key={i} className="u-backgroundWhite u-borderBottom1 u-borderTop1 u-borderColorLightest">{item.name}</ListSubheader>
                    }
                    return <MenuItem key={i} value={item.value} item={item}>{onName ? onName(item) : item.name}</MenuItem>
                })
            }
            </Select>
            {
                helperText ?
                    <FormHelperText>{helperText}</FormHelperText>
                    :
                    null
            }

        </FormControl>
    )
}
