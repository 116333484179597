import React from 'react';
import Notes from "@material-ui/icons/Notes";
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import RecordVoiceOverIcon from '@material-ui/icons/RecordVoiceOver';
import GitHubIcon from '@material-ui/icons/GitHub';
import LocationSearchingIcon from '@material-ui/icons/LocationSearching';
import {LogoWithAnimation} from "../Logo";
import CommonMenu from "./CommonMenu";
import SideMenu from "./SideMenu";
import "./style.styl";

export const services = [
    {name: "Digital Marketing", icon: <RecordVoiceOverIcon/>, link: "/service/digital-marketing", description: "Our marketing specialists know how to utilize digital advantages to best help your business.", children: [
        {name: "Social Media Marketing", link: "/service/digital-marketing/social-media-marketing"},
        {name: "Pay Per Click", link: "/service/digital-marketing/pay-per-click"},
        {name: "Content Marketing", link: "/service/digital-marketing/content-marketing"},
        {name: "Email Marketing", link: "/service/digital-marketing/email-marketing"}
    ]},
    {name: "SEO", link: "/service/seo", icon: <LocationSearchingIcon/>, description: "Our team makes your business more visible in searches with our custom SEO services.", children: [
        {name: "On-Page SEO", link: "/service/seo/on-page-seo"},
        {name: "Off-Page SEO", link: "/service/seo/off-page-seo"},
        {name: "SEO Copywriting", link: "/service/seo/seo-copywriting"}
    ]},
    {name: "Web Development", link: "/service/web-development", icon: <GitHubIcon/>, description: "Wescale provides the full web development service that includes custom web development.", children: [
        {name: "E-commerce Development", link: "/service/web-development/ecommerce-development"},
        {name: "Website Development", link: "/service/web-development/website-development"},
        {name: "Corporate Website", link: "/service/web-development/corporate-website"},
        {name: "Startup Website", link: "/service/web-development/startup-development"},
        {name: "Landings & Promo Sites", link: "/service/web-development/landings-and-promo-sites"}
    ]}
]


const menu = [
    {name: <span className="u-flex">Services <span className="u-sizeFullHeight u-flexCenter"><ArrowDropDownIcon fontSize="small" className=""/></span></span>, children: services},
    //{name: "Our Platform", link: "/platform"},
    {name: "We Hire", link: "/we-hire"}
];

export default function Header(props){
    const {location, sideMenu, rootClassName, isMobile, isDrawerRight, dispatch} = props;

    const url = location ? location.pathname : ""

    if (isMobile) {
        return (
            <header className={"app-header-main u-backgroundWhite u-absolute u-flexCenter u-justifyContentSpaceBetween" + rootClassName}
                    >
                <div className="app-header-wrapper container u-height65 u-flexCenter u-justifyContentSpaceBetween">
                    <div className="app-header-logo">
                        <LogoWithAnimation dispatch={dispatch}/>
                    </div>
                    <div>
                        <Notes className="icon-menu u-opacity80" onClick={() => dispatch("sideMenu", {isSideMenuOpen: true})}/>
                    </div>
                </div>
                <SideMenu isOpen={sideMenu.isSideMenuOpen} dispatch={dispatch}/>
            </header>
        )
    }

    return (
        <header className={"app-header-main u-backgroundWhite u-absolute u-flexCenter u-justifyContentSpaceBetween" + rootClassName}
                >
            <div className="app-header-wrapper container u-height65 u-flexCenter">
                <div className="app-header-logo u-xs-hide">
                    <LogoWithAnimation dispatch={dispatch}/>
                </div>

                <div className="u-flexCenter">
                    <nav className="app-header-menu u-flex u-paddingHorizontal10 u-marginRight15">
                        <CommonMenu items={menu} url={url} dispatch={dispatch}/>
                    </nav>



                    <a href="https://advistat.com" rel="noreferrer" target="_blank">
                        <span className="button-blue u-marginLeft5">Login</span>
                    </a>
                </div>
            </div>
        </header>
    );
}
