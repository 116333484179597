import React from 'react';
import SwipeableDrawer from "@material-ui/core/SwipeableDrawer";
import Main from "./Main";
import "./style.styl";


export default function SideMenu(props) {
    const {dispatch, isOpen} = props;

    const onClose = () => dispatch("sideMenu", {isSideMenuOpen: false});

    const goToPage = (link) => {
        dispatch(link);
        onClose();
    };

    return (
        <SwipeableDrawer open={isOpen}
                         onOpen={() => dispatch("sideMenu", {isSideMenuOpen: true})}
                         onClose={onClose}>
            <div className="u-flexColumn">
                <Main {...props} onClose={onClose} goToPage={goToPage}/>
            </div>
        </SwipeableDrawer>
    )
}





export const sideMenu = {
    isSideMenuOpen: false
};
