import React, {useMemo} from 'react';
import {withFormik, Form} from "formik";
import {dataSourceV2} from "../../DataSource";
import TextField from "@material-ui/core/TextField";
import Button from "@material-ui/core/Button";
import MuiSelect from "../..//MuiSelect";
import CircularProgress from "@material-ui/core/CircularProgress";
import {services} from "../../Header";
import "./style.styl";


const sourceList = [
    {name: "Telegram", value: "telegram"},
    {name: "WhatsApp", value: "whatsapp"},
    {name: "Email", value: "email"},
    {name: "Phone", value: "phone"}
]

const budgetList = [
    {name: "Less than $1,000", value: "less_than_1000"},
    {name: "$1,000 - $3,000", value: "1000_3000"},
    {name: "$3,000 - $5,000", value: "3000_5000"},
    {name: "$5,000 - $10,000", value: "5000_10000"},
    {name: "More than $10,000", value: "more_than_10000"}
]


function ContactForm(props) {
    const {values, errors, touched, isLarge, setFieldValue, isSubmitting} = props;

    const sourceSelected = useMemo(() => sourceList.filter(v => v.value === values.contact_source_type)[0], [values.contact_source_type])
    const servicesList = useMemo(() => {
        const items = []
        services.forEach((item, i) => {
            items.push({header: item.name, name: item.name, value: item.link});
            (item.children || []).forEach((item, i) => {
                items.push({name: item.name, value: item.link})
            });
            items.push({name: "Other", value: item.link + "/other"})

        });
        return items
    }, [])

    return (
        <Form>
            <div className="u-flexColumn">
                <MuiSelect      items={servicesList}
                                error={errors.service_id && touched.service_id}
                                helperText={errors.service_id && touched.service_id ? errors.service_id : ""}
                                selected={values.service_id}
                                htmlFor={"service_id"}
                                className="u-sizeFullWidth u-marginVertical10"
                                label="Service *"
                                margin={isLarge ? "normal" : "dense"}
                                onSelect={(value) => setFieldValue("service_id", value)}
                />

                <MuiSelect      items={budgetList}
                                error={errors.budget && touched.budget}
                                helperText={errors.budget && touched.budget ? errors.budget : ""}
                                selected={values.budget}
                                htmlFor={"budget"}
                                className="u-sizeFullWidth u-marginVertical10"
                                label="Budget *"
                                margin={isLarge ? "normal" : "dense"}
                                onSelect={(value) => setFieldValue("budget", value)}
                />

                <TextField
                    error={errors.comments && touched.comments}
                    label="Comments"
                    helperText={errors.comments && touched.comments ? errors.comments : ""}
                    margin={isLarge ? "normal" : "dense"}
                    multiline
                    rows={2}
                    placeholder="I'm looking for..."
                    className="u-sizeFullWidth u-marginVertical10"
                    onChange={(e) => setFieldValue("comments", e.target.value)}
                    value={values.comments}
                    variant="outlined"
                />

                <TextField
                    error={errors.name && touched.name}
                    helperText={errors.name && touched.name ? errors.name : ""}
                    label={"Your Name *"}
                    className="u-sizeFullWidth u-marginVertical10"
                    value={values.name}
                    margin={isLarge ? "normal" : "dense"}
                    onChange={(e) => setFieldValue("name", e.target.value)}
                    variant="outlined"
                />



                <MuiSelect      items={sourceList}
                                selected={values.contact_source_type}
                                htmlFor={"contact_source_type"}
                                className="u-sizeFullWidth u-marginVertical10"
                                label="Preferred Contact Source"
                                margin={isLarge ? "normal" : "dense"}
                                onSelect={(value) => setFieldValue("contact_source_type", value)}/>
                <TextField
                    error={errors.contact_source && touched.contact_source}
                    helperText={errors.contact_source && touched.contact_source ? errors.contact_source : ""}
                    label={"Your " + sourceSelected.name + " *"}
                    className="u-sizeFullWidth u-marginVertical10"
                    value={values.contact_source}
                    margin={isLarge ? "normal" : "dense"}
                    onChange={(e) => setFieldValue("contact_source", e.target.value)}
                    variant="outlined"
                />
            </div>
            <div className="u-flexCenter u-justifyContentCenter u-marginTop25 u-marginBottom20">
                {
                    isSubmitting ?
                        <div>
                            <CircularProgress />
                        </div>
                        :
                        <Button variant="outlined"
                                color="primary"
                                className="u-height50 button-dark-blue"
                                disabled={isSubmitting}
                                type="submit">
                                SUBMIT
                        </Button>
                }
            </div>
        </Form>
    );
}


export default ContactForm = withFormik({
    mapPropsToValues: (props) => ({
        service_id: props.selected || "",
        budget: "",
        comments: "",
        name: "",
        contact_source_type: "email",
        contact_source: "",
    }),

    validate: (values, props) => {
        let errors = {};
        if (!values.contact_source) {
            errors.contact_source = "Cannot be empty"
        }
        if (!values.name) {
            errors.name = "Cannot be empty"
        }
        if (!values.budget) {
            errors.budget = "Cannot be empty"
        }
        if (!values.service_id) {
            errors.service_id = "Cannot be empty"
        }

        return errors
    },

    handleSubmit: (values, props) => {
        const {onSubmit} = props.props;

        const data = {
            service_id: 'wescaleweb',
            template_id: 'request',
            user_id: 'user_zaw1C1DQepfSsNOI0hq9q',
            template_params: {
                "name": values.name,
                "data": JSON.stringify(values)
            }
        };
        dataSourceV2(`api/v1.0/email/send`, {data, url: "https://api.emailjs.com"})
            .then(v=>{
                props.setSubmitting(false);
                props.resetForm();
                onSubmit();
            })
    },

})(ContactForm);
