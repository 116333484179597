import React from 'react';
import parse from 'html-react-parser';
import "./style.styl";


export default function TitleBlock(props) {
    const {title, preview, description, actions, className, isAlignCenter, h1, isPreviewLeft} = props;

    return (
        <div className={"title-block " + (isAlignCenter ? " center " : "") + (isPreviewLeft ? " preview-left " : "") + (className || "")}>
            <div>
                {
                    preview ?
                        <span className="title-preview">
                            {preview}
                        </span>
                        :
                        null
                }
            </div>
            <div>
                {
                    title ?
                        (
                            h1 ?
                                <h1 className="title-title">
                                    {title}
                                </h1>
                                :
                                <h2 className="title-title">
                                    {title}
                                </h2>
                        )
                        :
                        null
                }
                {
                    description ?
                        <p className="title-description">
                            {(typeof description === "string" ? parse(description) : description)}
                        </p>
                        :
                        null
                }
                {
                    actions ?
                        <p className="title-actions">
                            {actions}
                        </p>
                        :
                        null
                }
            </div>
        </div>
    );
}
