import React from 'react';
import Menu, { SubMenu, Item as MenuItem } from 'rc-menu';
import 'rc-menu/assets/index.css';
import { Link } from "gatsby"
import "./style.styl"




function MenuButton(props) {
    const activeClass = props.isActive ? " active" : "";
    return (
        <span onClick={props.onClick}
              className={"menu-item u-flexCenter" + activeClass + " " + (props.className || "")}>
            {props.name}
        </span>
    )
}





export default function CommonMenu(props) {
    const {items, triggerSubMenuAction, defaultOpenKeys, url} = props;

    return (
      <div>
        <Menu
          onClick={(info) => console.log(info)}
          triggerSubMenuAction={triggerSubMenuAction}
          onOpenChange={() => null}
          defaultOpenKeys={defaultOpenKeys}
          mode="horizontal"
          openAnimation="slide-up"
        >
            {
                items.map((button, i) => {
                    return (
                        <SubMenu
                          title={<Link to={button.link || ""} disabled={!button.link}><MenuButton
                                      isActive={button.link && url && url.length > 2 && (button.link || "").includes(url || "")}
                                      name={button.name}/></Link>}
                          key={"0-" + i.toString()}
                          popupOffset={[10, 10]}
                        >
                            {
                                (button.children || []).map((item, i) => {
                                    const key = "1-" + i.toString()
                                    if (item.children) {
                                        return (

                                                <SubMenu
                                                    key={key}
                                                    title={(
                                                            <span key={key} className="submenu-title-wrapper">
                                                                <Link className="rc-menu-link" to={item.link}></Link>
                                                                {item.name}
                                                            </span>
                                                    )}
                                                >
                                                    {
                                                        item.children.map((item2, i) => {
                                                            const key = "2-" + i.toString()
                                                            return (

                                                                    <MenuItem key={key}>
                                                                        <Link key={key} className="rc-menu-link" to={item2.link}></Link>
                                                                        {item2.name}
                                                                    </MenuItem>

                                                            )
                                                        })
                                                    }
                                                </SubMenu>

                                        )
                                    }
                                    return (

                                            <MenuItem key={key}>
                                                <Link key={key} className="rc-menu-link" to={item.link}></Link>
                                                {item.name}
                                            </MenuItem>

                                    )
                                })
                            }
                        </SubMenu>
                    )
                })
            }
        </Menu>
      </div>
    );
}
