import React, { useState, useEffect } from 'react';
import ApplyForm from "../ApplyForm";
import SEO from "../SEO";
import "../style/mestyle.styl";
import '../style/medium-original.css';
import "./style.styl"





export default function Layout(props) {
    const { height, children, isMobile, dispatch } = props;

    const onResize = () => {
        const width = window.innerWidth;
        const height = window.innerHeight;
        const isMobile = width < 800;
        if (props.dispatch) {
            props.dispatch("style", {width, height, isMobile})
        }
    };


    useEffect(() => {
        if (window) {
            onResize();
            window.addEventListener("resize", onResize);
            return () => {
                window.removeEventListener("resize", onResize);
            }
        }
    }, []);




    return (
        <div style={{minHeight: height}}>
            <SEO {...props}/>
            {children}

            <ApplyForm  {...props}
                        isFullscreen={isMobile}/>
        </div>
    )
}
