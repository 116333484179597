import React from "react";
import {sideMenu} from "../Header/SideMenu";
import cookie from "react-cookie";


const token = cookie.load("token");
const defState = {
  style: {
    isSideMenuOpen: false,
    isMobile: false,
    height: 0,
    width: 0,
    isScroll100: false
  },
  device: {},
  customer: {},
  notification: {},
  sideMenu,
  dialog_core: {},
  login: {
      token: token === "undefined" ? "" : token
  }
};

const reducers = {
}

export const Store = React.createContext(defState);

export default class Provider extends React.Component {

  state = defState;

  dispatch = (content, payload, action) => {
    payload = reducers[content] ? reducers[content](this.state[content], payload, action, {state: {...this.state}}) || payload : payload

    if (payload) {
        if ("delete" in payload) {
          const d = {};
          d[content] = payload.delete;
          this.setState(d)
        } else {
          const state = this.state[content] || {};
          const newState = {...state, ...payload};
          const d = {};
          d[content] = newState;
          this.setState(d);
        }
      };
    }


  render() {
    return (
      <Store.Provider value={{...this.state, dispatch: this.dispatch}}>
        {this.props.children}
      </Store.Provider>
    );
  }
}
