import React from 'react';
import Divider from "@material-ui/core/Divider";
import List from "@material-ui/core/List";
import ListItemText from "@material-ui/core/ListItemText";
import ListItem from "@material-ui/core/ListItem";
import cookie from "react-cookie";
import Logo from "../../../Logo";
import {services} from "../../../Header";
import { Link } from "gatsby"
import "./style.styl";



export default function Main(props) {
    return (
        <List className="u-width240 u-padding0">
            <div className="u-paddingHorizontal24 u-flexCenter u-height65">
                <Logo/>
            </div>

            <Divider className="u-marginBottom10"/>

            <Link to="/">
                <ListItem className="u-paddingLeft24">
                    <ListItemText primary="Home"/>
                </ListItem>
            </Link>
            {/*<Link to="/platform">
                <ListItem className="u-paddingLeft24">
                    <ListItemText primary="Our Platform"/>
                </ListItem>
            </Link>*/}
            <Link to="/we-hire">
                <ListItem className="u-paddingLeft24">
                    <ListItemText primary="We Hire"/>
                </ListItem>
            </Link>

            <Divider className="u-marginVertical10"/>

            {
                services.map((service, i) => {
                    return (
                        <Link to={service.link}>
                            <ListItem className="u-paddingLeft24">
                                <ListItemText primary={service.name}/>
                            </ListItem>
                        </Link>
                    )
                })
            }

            <div className="u-flexCenter u-justifyContentCenter u-marginTop40">
                <a href="https://advistat.com" rel="noreferrer" target="_blank">
                    <span className="button-blue large">Login</span>
                </a>
            </div>
        </List>
    );
}



export const logout = (dispatch) => {
    cookie.remove("token", {path: "/"});
    if (dispatch) {
        dispatch()
    }
};
